import { ThemeProvider, CssBaseline } from '@mui/material'
import type { AppProps } from 'next/app'
import { darkTheme } from '../styles/Theme'

// modified version - allows for custom pageProps type, falling back to 'any'
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Props<P = any> = {
  pageProps: P
} & Omit<AppProps<P>, 'pageProps'>

// This default export is required in a new `pages/_app.js` file.
export default function MyApp({ Component, pageProps }: Props) {
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Component {...pageProps} />
    </ThemeProvider>
  )
}
